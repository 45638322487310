import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { ISearchBoxProps, ISearchBoxSDK } from '../SearchBox.types';
import {
  createElementPropsSDKFactory,
  focusPropsSDKFactory,
  textInputPropsSDKFactory,
  clickPropsSDKFactory,
  createStylePropsSDKFactory,
  disablePropsSDKFactory,
  changePropsSDKFactory,
} from '../../../core/corvid/props-factories';
import { searchBoxValuePropsSDKFactory } from './searchBoxValuePropsSDKFactory';

const elementPropsSDKFactory = createElementPropsSDKFactory();

const stylePropsSDKFactory = createStylePropsSDKFactory({
  BackgroundColor: true,
  BorderColor: true,
  BorderWidth: true,
  BorderRadius: true,
  TextColor: true,
});

export const sdk = composeSDKFactories<ISearchBoxProps, ISearchBoxSDK>(
  elementPropsSDKFactory,
  changePropsSDKFactory,
  textInputPropsSDKFactory,
  focusPropsSDKFactory,
  clickPropsSDKFactory,
  stylePropsSDKFactory,
  disablePropsSDKFactory,
  searchBoxValuePropsSDKFactory,
);
