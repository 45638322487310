import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { withValidation } from '@wix/editor-elements-corvid-utils';
import { StyleSDKOptions } from '../stylePropsSDKFactory';
import { getPixelValidator } from './validation';
import { getScopedVar } from './styleUtils';
import { cssVars } from './constants';
import { IStylePropsSDKOptions } from './types';

export type IBorderWidthSDK = {
  borderWidth: string;
  styleSDKOptions: StyleSDKOptions;
};

export const createBorderWidthPropsSDKFactory = (
  options?: Partial<IStylePropsSDKOptions>,
) => {
  const cssRule = getScopedVar({
    name: cssVars.borderWidth,
    prefix: options?.prefix,
  });

  const _borderWidthPropsSDKFactory: CorvidSDKPropsFactory<
    IBorderWidthSDK,
    { borderWidth?: string | null }
  > = ({ setStyles, sdkData }) => {
    let borderWidthState = options?.withoutDefaultValue
      ? undefined
      : sdkData.initialSdkStyles?.borderWidth;
    return {
      set borderWidth(borderWidth) {
        borderWidthState = borderWidth;
        setStyles({
          [cssRule]: borderWidth,
        });
      },
      get borderWidth() {
        return borderWidthState;
      },
    };
  };

  return withValidation(
    _borderWidthPropsSDKFactory,
    {
      type: ['object'],
      properties: {
        borderWidth: {
          type: ['string', 'nil'],
        },
      },
    },
    {
      borderWidth: [getPixelValidator('borderWidth', 'width')],
    },
  );
};
